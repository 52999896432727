import { Alert, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  GoalViewModel,
  QuestionnaireResponseDataViewModel,
  QuestionnaireResponseViewModel,
  QuestionnaireViewModel,
  QuestionType,
  Role,
} from "../../../types/auto/types";
import { CheckIsMobile } from "../../utils/MobileStatus";
import QuestionBox from "./questions/QuestionBox";
import parse from "html-react-parser";
import { useEffect, useRef, useState } from "react";
import "./PrintingTemplateCss.css";

interface Props {
  patientId?: string;
  questionnaire: QuestionnaireViewModel;
  questionnaireResponseData?: QuestionnaireResponseDataViewModel;
  role?: Role;
  caseloadId?: number;
  questionnaireResponseId?: number;
}

const PrintingTemplateForm = (props: Props): JSX.Element => {
  const useStyle = makeStyles(() => ({
    mainContainer: {
      "@media print": {
        margin: 0,
        padding: 0,
      },
    },
    copyright: {
      display: "flex",
      justifyContent: "center",
      "@media print": {
        breakBefore: "page",
        padding: 15,
      },
    },
    fillerFooter: {
      "@media print": {
        flex: "1 1 auto",
        breakAfter: "avoid-page",
      },
    },
    backgroundEagerLoad: {
      display: "none",
    },
  }));
  const classes = useStyle();

  const isMobile = CheckIsMobile();
  const [goalsFromQuestionnaire, setGoalsFromQuestionnaire] = useState<
    GoalViewModel[]
  >([]);
  const [responses, setResponses] = useState<QuestionnaireResponseViewModel[]>(
    props.questionnaireResponseData?.responses ??
      new Array<QuestionnaireResponseViewModel>()
  );
  const [template, setTemplate] = useState<JSX.Element[]>([]);
  const heights = useRef<number[]>(
    Array(props.questionnaire.sections?.length ?? 0)
  );

  const [refresh, setRefresh] = useState<boolean>(false);
  const [goalsQuestions] = useState<number>(getGoalsQuestionsCount);

  const goalsLoaded = useRef(0);

  const [effect, setEffect] = useState<boolean>(false);
  const [finalBuild, setFinalBuild] = useState<boolean>(false);

  const [observerSet, setObserverSet] = useState<boolean>(false);

  useEffect(
    () => {
      if (template.length === 0) {
        setTemplate(BuildPrintingTemplate());
      }
    },
    // eslint-disable-next-line
    [refresh]
  );

  useEffect(
    () => {
      if (template.length !== 0 && goalsLoaded.current === goalsQuestions) {
        calculateHeights();
      }
    },
    // eslint-disable-next-line
    [refresh]
  );

  useEffect(
    () => {
      if (finalBuild) {
        var localHeights = heights.current;
        setTemplate(BuildPrintingTemplate(undefined, localHeights));
      }
    },
    // eslint-disable-next-line
    [finalBuild]
  );

  useEffect(
    () => {
      if (
        template.length !== 0 &&
        goalsLoaded.current === goalsQuestions &&
        !observerSet
      ) {
        // create a new instance of `MutationObserver` named `observer`,
        // passing it a callback function
        const observer = new MutationObserver(() => {
          calculateHeights();
        });

        const target = document.getElementById("mainContainer") as Node;
        // call `observe()`, passing it the element to observe, and the options object
        observer.observe(target, {
          subtree: true,
          childList: true,
        });
        setObserverSet(true);
      }
    },
    // eslint-disable-next-line
    [refresh]
  );

  function getGoalsQuestionsCount() {
    var localCount = 0;
    if (props.questionnaire.sections) {
      for (var section of props.questionnaire.sections) {
        if (section.questions) {
          for (var question of section.questions) {
            if (question && question.questionType === QuestionType.Goals) {
              localCount++;
            }
          }
        }
      }
    }
    return localCount;
  }

  function updateGoalsLoadedCount() {
    goalsLoaded.current = goalsLoaded.current + 1;
    setEffect((x) => !x);
  }

  useEffect(
    () => {
      if (goalsLoaded.current === goalsQuestions) {
        calculateHeights();
      }
    },
    // eslint-disable-next-line
    [effect]
  );

  function calculateHeights() {
    var localHeights: number[] = Array(heights.current.length);
    var reRender = false;
    for (var i = 0; i < heights.current.length; i++) {
      var sectionHeight = 15;
      if (
        document.getElementById(i.toString()) != null &&
        document.getElementById(i.toString())!.offsetHeight != null
      ) {
        var section = document.getElementById(i.toString());
        var children = section?.children;
        if (children) {
          for (var child of children) {
            if (child.children && child.children.length !== 0) {
              var margin;
              var height;
              var currentPageUsed;
              for (var c of child.children) {
                const computedStyle = getComputedStyle(c);
                margin =
                  parseInt(computedStyle.marginTop, 10) +
                  parseInt(computedStyle.marginBottom, 10);
                height = (c as HTMLElement).offsetHeight + margin;
                currentPageUsed = sectionHeight % 1123;
                if (currentPageUsed + height > 1123) {
                  sectionHeight += 1123 - currentPageUsed;
                }
                sectionHeight += height;
              }
            } else {
              const computedStyle = getComputedStyle(child);
              margin =
                parseInt(computedStyle.marginTop, 10) +
                parseInt(computedStyle.marginBottom, 10);
              height = (child as HTMLElement).offsetHeight + margin;
              currentPageUsed = sectionHeight % 1123;
              if (currentPageUsed + height > 1123) {
                sectionHeight += 1123 - currentPageUsed;
              }
              sectionHeight += height;
            }
          }
        }
        localHeights[i] = Math.max(
          sectionHeight,
          document.getElementById(i.toString())!.offsetHeight
        );
      }
      if (localHeights[i] !== heights.current[i]) {
        reRender = true;
      }
    }
    if (reRender) {
      setTemplate(BuildPrintingTemplate());
    } else {
      setTemplate(BuildPrintingTemplate(true));
    }
    heights.current = localHeights;
  }

  function addExtraPadding(
    j: number,
    heading: string | undefined,
    subheading: string | undefined
  ) {
    // Always skip padding on first component.
    if (j === 0) return [false, false];
    // Larger paddingTop on second element to compensate for missing 1 paddingBottom from above element
    if (j === 1) return [true, true];
    // Always add padding on non-first component
    if (j > 1) return [true, false];

    // If both heading and section title are empty, do not add padding
    return [Boolean(heading || subheading), false];
  }

  useEffect(() => {
    if (props.questionnaireResponseData?.responses) {
      setResponses(props.questionnaireResponseData?.responses);
    }
  }, [setResponses, props.questionnaireResponseData]);

  function BuildPrintingTemplate(x?: boolean, finalHeights?: number[]) {
    var singlePageQuestionnaire: JSX.Element[] = [];
    if (
      props.questionnaire.sections != null &&
      props.questionnaire.sections.length > 0
    ) {
      for (var i = 0; i < props.questionnaire.sections?.length; i++) {
        var childComponents: JSX.Element[] = [];
        var currentSection = props.questionnaire.sections[i];

        if (i === 0) {
          childComponents.push(
            <Typography
              variant={isMobile ? "h5" : "h4"}
              component="h1"
              gutterBottom
              sx={{
                fontWeight: "bold",
                m: 2,
                ml: isMobile ? 0 : 2,
                "@media print": {
                  paddingLeft: 1.5,
                },
              }}
              key={"questionnaire-title-" + i}
            >
              {props.questionnaire.name != null
                ? props.questionnaire.name === "Care Plan"
                  ? "Co-produced Care Plan"
                  : props.questionnaire.name
                : null}
            </Typography>
          );
        }

        // HEADER
        if (currentSection.heading) {
          childComponents.push(
            <Typography
              variant={isMobile ? "h6" : "h5"}
              component="h1"
              gutterBottom
              sx={{
                fontWeight: "bold",
                m: 2,
                ml: isMobile ? 0 : 2,
                "@media print": {
                  paddingLeft: 1.5,
                },
              }}
              key={"section-heading-" + i}
            >
              {currentSection.heading ?? null}
            </Typography>
          );
        }

        // SECTION TITlE
        if (currentSection.sectionTitle) {
          childComponents.push(
            <Alert
              key={"sectionTitle-" + currentSection.sectionTitle + i}
              severity="info"
            >
              {currentSection.sectionTitle}
            </Alert>
          );
        }

        // QUESTIONS
        const questions = currentSection.questions;
        if (questions != null) {
          for (var j = 0; j < questions.length; j++) {
            childComponents.push(
              <div
                key={
                  "questionContainer-" + questions[j].number + "-" + j + "-" + i
                }
              >
                <QuestionBox
                  key={"question-" + questions[j].number}
                  question={questions[j]}
                  setResponses={setResponses}
                  responses={
                    responses || new Array<QuestionnaireResponseViewModel>()
                  }
                  setRerender={() => {}}
                  patientId={props.patientId}
                  questionnaireId={props.questionnaire.id}
                  role={
                    props.questionnaire?.authenticatedOnly
                      ? props.role
                      : undefined
                  }
                  goalsFromQuestionnaire={goalsFromQuestionnaire}
                  setGoalsFromQuestionnaire={setGoalsFromQuestionnaire}
                  preview={false}
                  questions={questions}
                  caseloadId={props.caseloadId}
                  readOnly={true}
                  extraPrintPadding={addExtraPadding(
                    j,
                    currentSection.heading,
                    currentSection.sectionTitle
                  )}
                  setHasUnsubmittedGoals={() => {}}
                  questionnaireResponseId={props.questionnaireResponseId}
                  isLastQuestion={j === questions.length - 1 ? true : undefined}
                  incrementGoalsLoadedCount={() => {
                    updateGoalsLoadedCount();
                  }}
                />
              </div>
            );
          }
        }

        singlePageQuestionnaire.push(
          <Box
            key={"section-box-" + i}
            id={i.toString()}
            sx={{
              paddingLeft: "15px",
              paddingRight: "15px",
              paddingTop: "15px",
              paddingBottom: "0px",
              maxWidth: "794px",
              "@media print": {
                backgroundImage:
                  "url(" +
                  props.questionnaire?.sections?.[i].backgroundImage +
                  ")",
                minHeight:
                  (
                    Math.trunc(
                      (finalHeights !== undefined
                        ? finalHeights[i]
                        : heights.current[i]) / 1123
                    ) + 1
                  ).toString() + "00vh",
                // do not remove this border!!
                border: "1px solid transparent",
                breakInside: "avoid",
                backgroundSize: "100vw 100vh",
                backgroundRepeat:
                  heights.current[i] > 1123 ? "repeat-y" : "no-repeat",
                backgroundPosition: "top",
              },
            }}
          >
            {childComponents}
          </Box>
        );
      }
      if (props.questionnaire.copyright != null) {
        singlePageQuestionnaire.push(
          <div className={classes.copyright} key={"copyright-box-" + i}>
            <Typography variant="body2" component="h1">
              {parse(props.questionnaire.copyright ?? "")}
            </Typography>
          </div>
        );
      }
      singlePageQuestionnaire.push(
        <div className={classes.fillerFooter} key={"filler"}></div>
      );
    }
    setRefresh((x) => !x);
    setFinalBuild(x ?? false);
    return singlePageQuestionnaire;
  }

  return (
    <div
      className={classes.mainContainer}
      key={"main-container-printing-template"}
      id="mainContainer"
    >
      {template}
      {/* Please include any required background images here. This will load them in in the backround so the print preview always renders with the image present. */}
      <div className={classes.backgroundEagerLoad}>
        {props.questionnaire.name === "Care Plan" && (
          <>
            <img
              src="backgroundImages/careplan_plant.png"
              alt="careplan_plant"
            ></img>
            <img
              src="backgroundImages/careplan_noplant.png"
              alt="careplan_plant"
            ></img>
          </>
        )}
      </div>
    </div>
  );
};

export default PrintingTemplateForm;
