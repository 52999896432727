import {
  Button,
  Card,
  CardContent,
  Container,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from "@mui/material";
import React from "react";
import {
  PatientViewModel,
  TimelineCardType,
  PatientTimelineDataViewModel,
  Role,
  UserViewModel,
  ResponseState,
} from "../../../../../types/auto/types";
import { ResponseInformation } from "../../timeline/ResponseInformation";
import { makeStyles } from "@mui/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Delete, Edit, Print } from "@mui/icons-material";
import { CheckIsMobile } from "../../../../utils/MobileStatus";
import GradingIcon from "@mui/icons-material/Grading";
import FlagIcon from "@mui/icons-material/Flag";
import RateGoalModal from "../../../goals/RateGoalModal";
import { theme } from "../../../../../Theme";
import { DeletePromptTimeline } from "../../timeline/DeletePromptTimeline";
import { Configuration } from "../../../../Constants";
import CustomDialog from "../../../elements/dialog/CustomDialog";
import EditResponseDate from "../../dialog/EditResponseDate";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Error401 } from "../../../elements/errors/Error401";
import moment from "moment";
import { LargerThanDateIncludingHoursMinutes } from "../../../../utils/Dates";

interface Props {
  timelineEntree: PatientTimelineDataViewModel;
  index: number;
  patient: PatientViewModel;
  user: UserViewModel;
  canEditResponses: boolean;
  refreshTimeline: () => void;
  currentTimelineLength: number;
  totalTimelineLength: number;
  totalTimelineLengthFiltered: number;
  setCurrentTimelineLength: React.Dispatch<React.SetStateAction<number>>;
  setTotalTimelineLengthFiltered: React.Dispatch<React.SetStateAction<number>>;
  setTotalTimelineLength: React.Dispatch<React.SetStateAction<number>>;
  setHiddenArrayIndex: () => void;
  isLastItem: boolean;
  questionnairesWithPrintingTemplateEnabled: number[];
}
export const ResponseCard = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const [hideCard, setHideCard] = React.useState(false);
  const useStyle = makeStyles((th: Theme) => ({
    buttonContainer: {
      display: "flex",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    headerAndActions: {
      display: "flex",
      paddingRight: "0",
      paddingLeft: "0",
    },
    headingText: {
      fontWeight: "bold",
    },
    vert: {
      marginLeft: "auto",
      padding: "0",
      minWidth: "24px",
    },
    cardText: {
      display: !isMobile ? "inline" : "block",
    },
    cardContent: {
      padding: 8,
    },
    cardActions: {
      padding: 0,
      display:
        props.timelineEntree.type === TimelineCardType.Goal ||
        props.timelineEntree.questionnaireResponse === undefined
          ? "none"
          : "block",
    },
    card: {
      marginBottom: "10px",
      marginTop: props.index === 0 && isMobile ? 0 : "10px",
      display: hideCard ? "none" : "block",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
    },
    typeIcon: {
      verticalAlign: "text-bottom",
      marginRight: "5px",
    },
  }));
  const classes = useStyle(theme);

  var submittedText = "";

  var mainScore =
    props.timelineEntree.questionnaireScores === undefined ||
    props.timelineEntree.questionnaireScores === null
      ? null
      : props.timelineEntree.questionnaireScores?.filter(
          (x) => x.name === "Main"
        )[0];

  if (!props.timelineEntree.completed) {
    if (props.timelineEntree.questionnaireResponse === undefined) {
      submittedText =
        props.timelineEntree.questionnaireResponseState !== ResponseState.Draft
          ? "Scheduled at " +
            props.timelineEntree.submitted?.toLocaleTimeString().slice(0, 5)
          : props.timelineEntree.submitted &&
            props.timelineEntree.edited &&
            LargerThanDateIncludingHoursMinutes(
              props.timelineEntree.edited,
              props.timelineEntree.submitted
            )
          ? `Draft edited on ${moment(props.timelineEntree.edited).format(
              "DD/MM/YYYY [at] HH:mm"
            )}`
          : "Drafted at " +
            props.timelineEntree.submitted?.toLocaleTimeString().slice(0, 5);
    } else {
      submittedText =
        "Recieved at " +
        props.timelineEntree.submitted?.toLocaleTimeString().slice(0, 5);
    }
  } else {
    submittedText =
      "Submitted at " +
        props.timelineEntree.submitted?.toLocaleTimeString().slice(0, 5) ?? "";

    // If the response has been edited, show this

    if (
      props.timelineEntree.edited &&
      props.timelineEntree.submitted &&
      LargerThanDateIncludingHoursMinutes(
        props.timelineEntree.edited,
        props.timelineEntree.submitted
      )
    ) {
      submittedText += ` (Edited on ${moment(
        props.timelineEntree.edited
      ).format("DD/MM/YYYY [at] HH:mm")})`;
    }

    if (mainScore !== undefined && mainScore != null) {
      submittedText = submittedText + " scoring " + mainScore.score;
    }
  }

  const [rateGoalOpen, setRateGoalOpen] = React.useState(false);
  const [deletePrompt, setDeletePrompt] = React.useState(false);
  const [editResponseDate, setEditResponseDate] = React.useState(false);
  const [errorClinicianNotLinked, setErrorClinicianNotLinked] =
    React.useState(false);

  const [anchorActionList, setAnchorActionList] =
    React.useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorActionList(null);
  };

  function deleteResponse() {
    setDeletePrompt(true);
  }

  function editResponse() {
    if (props.timelineEntree.type === TimelineCardType.Goal) {
      setRateGoalOpen(true);
    } else {
      window.location.href =
        Configuration.SITEBASE +
        "/questionnaire?p=" +
        props.timelineEntree.patientId +
        "&t=" +
        props.timelineEntree.responseToken +
        "&tId=" +
        props.patient.tenantId +
        "&e=1" +
        "&c=" +
        (window.location.href.indexOf("contextual") > -1 ? "1" : "0");
    }
  }

  function printPreview() {
    if (props.timelineEntree.type === TimelineCardType.Goal) {
      setRateGoalOpen(true);
    } else {
      window.location.href =
        Configuration.SITEBASE +
        "/printingTemplate?p=" +
        props.timelineEntree.patientId +
        "&t=" +
        props.timelineEntree.responseToken;
    }
  }

  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Container className={classes.headerAndActions}>
          <Typography className={classes.headingText}>
            {props.timelineEntree.type === TimelineCardType.Questionnaire ? (
              <GradingIcon className={classes.typeIcon} />
            ) : (
              <FlagIcon className={classes.typeIcon} />
            )}
            {props.timelineEntree.name}{" "}
            {props.timelineEntree.questionnaireVersion !== null
              ? " - version " + props.timelineEntree.questionnaireVersion
              : ""}
          </Typography>
          {props.timelineEntree.questionnaireResponseState ===
          ResponseState.Draft ? (
            <Button
              onClick={(event) =>
                setAnchorActionList((val) =>
                  val === null ? event.currentTarget : null
                )
              }
              aria-label="Open Action List"
              className={classes.vert}
            >
              <>
                <MoreVertIcon />
                <Menu
                  open={anchorActionList !== null}
                  anchorEl={anchorActionList}
                  aria-label="Action List"
                  onClose={handleClose}
                >
                  <MenuItem onClick={editResponse} aria-label="Edit draft">
                    <Edit /> Edit response
                  </MenuItem>
                  <MenuItem
                    onClick={deleteResponse}
                    aria-label="Delete response"
                  >
                    <Delete /> Delete response
                  </MenuItem>
                </Menu>
              </>
            </Button>
          ) : (
            ((props.user.role === Role.Clinician && props.canEditResponses) ||
              props.user.role === Role.Admin ||
              props.user.role === Role.SuperAdmin) && (
              <Button
                onClick={(event) =>
                  setAnchorActionList((val) =>
                    val === null ? event.currentTarget : null
                  )
                }
                aria-label="Open Action List"
                className={classes.vert}
              >
                <>
                  <MoreVertIcon />
                  <Menu
                    open={anchorActionList !== null}
                    anchorEl={anchorActionList}
                    aria-label="Action List"
                    onClose={handleClose}
                  >
                    <MenuItem onClick={editResponse} aria-label="Edit response">
                      <Edit /> Edit response
                    </MenuItem>
                    <MenuItem
                      onClick={() => setEditResponseDate(true)}
                      aria-label="Edit response date"
                    >
                      <CalendarMonthIcon /> Edit response date
                    </MenuItem>
                    {props.questionnairesWithPrintingTemplateEnabled.includes(
                      props.timelineEntree.questionnaireId ?? -1
                    ) && (
                      <MenuItem
                        onClick={() => printPreview()}
                        aria-label="View Questionnaire"
                      >
                        <Print /> View Questionnaire
                      </MenuItem>
                    )}
                    {(props.user.role === Role.Admin ||
                      props.user.role === Role.SuperAdmin) && (
                      <MenuItem
                        onClick={deleteResponse}
                        aria-label="Delete response"
                      >
                        <Delete /> Delete response
                      </MenuItem>
                    )}
                  </Menu>
                </>
              </Button>
            )
          )}
          <RateGoalModal
            patient={props.patient}
            goalId={props.timelineEntree.goalId ?? 0}
            goalQuestion={props.timelineEntree.name ?? ""}
            open={rateGoalOpen}
            closeModal={() => {
              setRateGoalOpen(false);
              props.refreshTimeline();
            }}
            goalResponseId={props.timelineEntree.responseId}
            goalScore={props.timelineEntree.goalResponse}
          />
          <DeletePromptTimeline
            timelineEntree={props.timelineEntree}
            patient={props.patient}
            deletePrompt={deletePrompt}
            setDeletePrompt={setDeletePrompt}
            setHideCard={setHideCard}
            currentTimelineLength={props.currentTimelineLength}
            setCurrentTimelineLength={props.setCurrentTimelineLength}
            totalTimelineLength={props.totalTimelineLength}
            setTotalTimelineLength={props.setTotalTimelineLength}
            totalTimelineLengthFiltered={props.totalTimelineLengthFiltered}
            setTotalTimelineLengthFiltered={
              props.setTotalTimelineLengthFiltered
            }
            isLastItem={props.isLastItem}
            setHiddenArrayIndex={props.setHiddenArrayIndex}
            setErrorClinicianNotLinked={setErrorClinicianNotLinked}
          />
          <CustomDialog
            open={editResponseDate}
            onClose={() => setEditResponseDate(false)}
            title="Edit response date"
          >
            <EditResponseDate
              timelineEntree={props.timelineEntree}
              refreshTimeline={() => {
                setEditResponseDate(false);
                props.refreshTimeline();
              }}
              setErrorClinicianNotLinked={setErrorClinicianNotLinked}
            />
          </CustomDialog>
        </Container>
        <Typography className={classes.cardText}>{submittedText}</Typography>
        <Typography className={classes.cardText}>
          {isMobile ? "F" : " f"}or {props.timelineEntree.caseloadName} by
          {props.timelineEntree.staffId === undefined ||
          props.timelineEntree.staffId === null ||
          props.timelineEntree.staffId === 0
            ? " patient"
            : " " + Role[props.timelineEntree.role ?? 0] + " "}
          {props.timelineEntree.staffName}
        </Typography>
        {props.timelineEntree.questionnaireResponse === undefined ? (
          <Typography>
            {props.timelineEntree.goalResponse !== undefined &&
            props.timelineEntree.goalResponse !== null &&
            props.timelineEntree.type === TimelineCardType.Goal
              ? "Response: " + props.timelineEntree.goalResponse
              : props.timelineEntree.questionnaireResponseState ===
                ResponseState.Draft
              ? "Draft Response"
              : "No Response"}
          </Typography>
        ) : (
          <></>
        )}
      </CardContent>
      {props.timelineEntree.type === TimelineCardType.Questionnaire &&
      props.timelineEntree.questionnaireResponse !== undefined ? (
        <ResponseInformation timelineEntree={props.timelineEntree} />
      ) : (
        <></>
      )}
      {errorClinicianNotLinked && <Error401 />}
    </Card>
  );
};
