import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../../Theme";
import { useEffect, useState } from "react";
import CreateEditScheduleForm from "./CreateEditScheduleForm";
import {
  PatientViewModel,
  PreferredContact,
  QuestionnaireViewModel,
  ScheduleClient,
  ScheduleViewModel,
} from "../../../../types/auto/types";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import { FetchOverride } from "../../../utils/Request";
import { Configuration } from "../../../Constants";
import {
  IsCaseloadValid,
  IsEndDateValid,
  IsQuestionnaireValid,
} from "./CaseloadQuestionnaireDropdownHelpers";
import { useMountedState } from "react-use";

interface Props {
  schedule?: ScheduleViewModel;
  patient: PatientViewModel;
  open: boolean;
  closeModal: () => void;
  refreshSchedules: () => void;
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  maxHeight: "85%",
};

const CreateSchedule = async (
  schedule: ScheduleViewModel,
  patient: PatientViewModel,
  selectedQuestionnaires: QuestionnaireViewModel[]
) => {
  schedule.patientId = patient.id;

  // Iterate over the questionnaires and set a schedule for each one
  for (const questionnaire of selectedQuestionnaires) {
    schedule.questionnaireId = questionnaire.id;
    await new ScheduleClient(
      Configuration.SERVER_ROOT,
      FetchOverride
    ).createSchedule(schedule);
  }
};

const UpdateSchedule = (
  schedule: ScheduleViewModel,
  refreshSchedules: () => void
) => {
  new ScheduleClient(Configuration.SERVER_ROOT, FetchOverride)
    .putSchedule(schedule)
    .then(() => {
      refreshSchedules();
    });
};

const CreateEditScheduleModal = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles({
    header: {
      marginBottom: 20,
      color: "black",
      fontSize: 30,
    },
    actionButtonGroup: {
      textAlign: "right",
    },
  });
  const classes = useStyle(customTheme);
  const [schedule, setSchedule] = useState<ScheduleViewModel>(
    props.schedule || ({} as ScheduleViewModel)
  );
  const [promptschedule, setPromptSchedule] = useState(
    props.schedule?.promptSchedule
  );
  const [caseloadError, setCaseloadError] = useState(false);
  const [questionnaireError, setQuestionnaireError] = useState(false);
  const [openNullEmailDialog, setOpenNullEmailDialog] = useState(false);
  const [promptCheckBox, setPromptCheckBox] = useState(false);
  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState<
    QuestionnaireViewModel[]
  >([]);
  const [editingForm, setEditingForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledPrompt, setDisabledPromt] = useState(false);

  const isMountedState = useMountedState();

  useEffect(() => {
    if (isMountedState()) {
      setSchedule(props.schedule || ({} as ScheduleViewModel));
      setEditingForm(props.schedule !== undefined);
      setPromptSchedule(props.schedule?.promptSchedule || undefined);
    }
    if (props.schedule?.questionnaire?.authenticatedOnly) {
      setDisabledPromt(true);
    } else {
      setDisabledPromt(false);
    }
  }, [props.schedule, setSchedule, props.open, isMountedState]);

  const modalName = (editingForm ? "Edit" : "Create") + " Schedule";
  const buttonName = (editingForm ? "Save" : "Create") + " Schedule";

  return (
    <Modal open={props.open} sx={{ overflow: "scroll" }}>
      <Box
        sx={{
          ...modalStyle,
          width: isMobile ? "100%" : "60%",
          overflowY: isMobile ? "scroll" : "auto",
        }}
      >
        <Typography variant="h5" component="h2" className={classes.header}>
          {modalName}
        </Typography>
        <CreateEditScheduleForm
          patient={props.patient}
          schedule={schedule}
          setSchedule={setSchedule}
          editing={editingForm}
          selectedQuestionnaires={selectedQuestionnaires}
          setSelectedQuestionnaires={setSelectedQuestionnaires}
          caseloadError={caseloadError}
          questionnaireError={questionnaireError}
          promptSchedule={promptschedule}
          setPromptSchedule={setPromptSchedule}
          promptCheckBox={promptCheckBox}
          setPromptCheckBox={setPromptCheckBox}
          disabledPromt={disabledPrompt}
        />

        <div className={classes.actionButtonGroup}>
          <Button
            onClick={() => {
              setCaseloadError(false);
              setQuestionnaireError(false);
              setSelectedQuestionnaires([]);
              props.closeModal();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={async () => {
              if (
                props.patient.preferredContact === PreferredContact.Email &&
                !props.patient.contactEmail &&
                promptschedule &&
                !editingForm
              ) {
                setOpenNullEmailDialog(true);
                return;
              }

              const caseloadErr = !IsCaseloadValid(schedule.caseloadId);

              var questionnaireErr = false;
              if (!editingForm) {
                questionnaireErr = !IsQuestionnaireValid(
                  selectedQuestionnaires
                );
              }

              const endDateErr = !IsEndDateValid(
                schedule.startDate,
                schedule.endDate,
                schedule.continueIndefinitely ?? false
              );

              setCaseloadError(caseloadErr);
              setQuestionnaireError(questionnaireErr);

              if (
                caseloadErr ||
                questionnaireErr ||
                endDateErr ||
                (promptCheckBox && promptschedule?.timeOfDay === undefined)
              ) {
                return;
              }

              setLoading(true);
              if (promptschedule !== undefined) {
                if (
                  promptschedule.frequencyAmount === undefined ||
                  promptschedule.frequencyAmount === null
                ) {
                  promptschedule.frequencyAmount = 1;
                }
              }
              const scheduleToSend = schedule;
              scheduleToSend.promptSchedule = promptschedule;
              if (editingForm) {
                await UpdateSchedule(scheduleToSend, props.refreshSchedules);
              } else {
                await CreateSchedule(
                  scheduleToSend,
                  props.patient,
                  selectedQuestionnaires
                );
              }
              props.closeModal();
              setLoading(false);
              setSelectedQuestionnaires([]);
            }}
          >
            {buttonName}
          </LoadingButton>
          <Dialog
            open={openNullEmailDialog}
            onClose={() => {
              setOpenNullEmailDialog(false);
            }}
          >
            <DialogTitle> {"Failed to create prompt schedule"} </DialogTitle>
            <DialogContent>
              <DialogContentText>
                The patient has an email only contact preference without a valid
                contact email address. Please assign the patient a valid contact
                email address.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenNullEmailDialog(false);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Box>
    </Modal>
  );
};

export default CreateEditScheduleModal;
