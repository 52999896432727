import {
  AnswerViewModel,
  CategoryType,
  IAnswerViewModel,
  IQuestionViewModel,
  IQuestionnaireViewModel,
  IScoringLogicViewModel,
  ISectionViewModel,
  IPatientDashboardOptionsViewModel,
  IPatientDashboardRegionOptionsViewModel,
  PatientDashboardRegionOptionsViewModel,
  PatientDashboardOptionsViewModel,
  QuestionType,
  QuestionViewModel,
  QuestionnaireViewModel,
  ScoringLogicViewModel,
  ScoringTransformationViewModel,
  SectionViewModel,
  TableQuestionType,
  ScoringRuleOperator,
  ScoringRuleViewModel,
  IScoringTransformationViewModel,
  IScoringRuleViewModel,
  GoalCategory,
  Autofill,
  AdditionalPlotViewModel,
  IAdditionalPlotViewModel,
} from "../../../../types/auto/types";
import { DependsOn } from "./DependsOnBuilder";

export enum ChangeType {
  Undefined,
  Add,
  Delete,
}

export function generateQuestionnaireBuilderDataModel(
  q: QuestionnaireViewModel
) {
  return mapQuestionnaire(q);
}

export function getLastQuestionNumberHelper(q: QuestionnaireBuilderDataModel) {
  if (q.sections.length !== 0) {
    for (var i = q.sections.length - 1; i > -1; i--) {
      if (q.sections[i].questions.length === 0) {
        continue;
      } else {
        return (
          q.sections[i].questions[q.sections[i].questions.length - 1].number ??
          0
        );
      }
    }
  }
  return 0;
}

export function getNextQuestionNumberHelper(
  q: QuestionnaireBuilderDataModel,
  s: SectionDataModel
) {
  if (q.sections.length !== 0) {
    var qNumCount = 0;
    for (var i = 0; i < q.sections.length; i++) {
      if (q.sections[i] === s) {
        if (q.sections[i].questions.length === 0) {
          return qNumCount + 1;
        } else {
          for (var x = 0; x < q.sections[i].questions.length; x++) {
            qNumCount++;
          }
        }
        return qNumCount + 1;
      } else {
        if (q.sections[i].questions.length === 0) {
          continue;
        } else {
          for (var z = 0; z < q.sections[i].questions.length; z++) {
            qNumCount++;
          }
        }
      }
    }
  }
  return 1;
}

export function updateQuestionNumbersHelper(
  q: QuestionnaireBuilderDataModel,
  questionNum: number,
  questionNumChangeType: ChangeType,
  sectionNumChange: number,
  sectionQuestionNums: number[],
  sectionNumChangeType: ChangeType
) {
  if (q.sections.length === 0) {
    return { ...q };
  }

  var qNum = 1;
  var updatedQ: QuestionnaireBuilderDataModel = { ...q };
  updatedQ.authenticatedOnly = false;

  for (var i = 0; i < updatedQ.sections.length; i++) {
    if (updatedQ.sections[i].dependsOn != null) {
      const currDep: DependsOn = updatedQ.sections[i].dependsOn!;
      var newDepSec = updateDependsOnHelper(
        currDep,
        sectionNumChangeType,
        sectionNumChange,
        i,
        sectionQuestionNums,
        questionNum,
        questionNumChangeType
      );
      Object.keys(newDepSec).length === 0
        ? (updatedQ.sections[i].dependsOn = undefined)
        : (updatedQ.sections[i].dependsOn = newDepSec);
    }
    if (updatedQ.sections[i].questions.length === 0) {
      continue;
    } else {
      for (var x = 0; x < updatedQ.sections[i].questions.length; x++) {
        if (qNum >= questionNum) {
          updatedQ.sections[i].questions[x].number = qNum;
        }
        if (
          updatedQ.sections[i].questions[x].questionType === QuestionType.Goals
        ) {
          updatedQ.authenticatedOnly = true;
        }
        if (updatedQ.sections[i].questions[x].dependsOn != null) {
          const currDep: DependsOn =
            updatedQ.sections[i].questions[x].dependsOn!;
          var newDep = updateDependsOnHelper(
            currDep,
            sectionNumChangeType,
            sectionNumChange,
            i,
            sectionQuestionNums,
            questionNum,
            questionNumChangeType
          );
          Object.keys(newDep).length === 0
            ? (updatedQ.sections[i].questions[x].dependsOn = undefined)
            : (updatedQ.sections[i].questions[x].dependsOn = newDep);
        }
        qNum++;
      }
    }
  }
  return updatedQ;
}

function updateDependsOnHelper(
  currDep: DependsOn,
  sectionNumChangeType: ChangeType,
  sectionNumChange: number,
  currSec: number,
  sectionQuestionNums: number[],
  questionNum: number,
  questionNumChangeType: ChangeType
) {
  var newDep: DependsOn = {};
  for (const [key, value] of Object.entries(currDep)) {
    if (
      sectionNumChangeType === ChangeType.Delete &&
      sectionNumChange <= currSec
    ) {
      if (sectionQuestionNums.includes(Number(key))) {
        continue;
      } else if (sectionQuestionNums[0] < Number(key)) {
        newDep = {
          ...newDep,
          [(Number(key) - sectionQuestionNums.length).toString()]: value,
        };
      } else {
        newDep = {
          ...newDep,
          [key]: value,
        };
      }
    } else if (
      questionNum === Number(key) &&
      questionNumChangeType === ChangeType.Delete
    ) {
      continue;
    } else if (
      questionNum < Number(key) &&
      questionNumChangeType === ChangeType.Delete
    ) {
      newDep = {
        ...newDep,
        [(Number(key) - 1).toString()]: value,
      };
    } else if (
      questionNum <= Number(key) &&
      questionNumChangeType === ChangeType.Add
    ) {
      newDep = {
        ...newDep,
        [(Number(key) + 1).toString()]: value,
      };
    } else {
      newDep = {
        ...newDep,
        [key]: value,
      };
    }
  }
  return newDep;
}

export function updateAnswerNumbersHelper(q: QuestionnaireBuilderDataModel) {
  if (q.sections.length === 0) {
    return { ...q };
  }

  var updatedQ: QuestionnaireBuilderDataModel = { ...q };

  for (var i = 0; i < updatedQ.sections.length; i++) {
    if (updatedQ.sections[i].questions.length === 0) {
      continue;
    } else {
      for (var x = 0; x < updatedQ.sections[i].questions.length; x++) {
        if (updatedQ.sections[i].questions[x].answers.length === 0) {
          continue;
        } else {
          for (
            var z = 0;
            z < updatedQ.sections[i].questions[x].answers.length;
            z++
          ) {
            updatedQ.sections[i].questions[x].answers[z] = {
              ...updatedQ.sections[i].questions[x].answers[z],
              number: z + 1,
            };
          }
        }
      }
    }
  }
  return updatedQ;
}

export interface QuestionnaireBuilderDataModel {
  id?: number;
  active?: boolean;
  created?: Date;
  updated?: Date;
  name?: string | undefined;
  spreadSheetName?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  sections: SectionDataModel[];
  version?: number | undefined;
  responseId?: number | undefined;
  scoring: ScoringDataModel[];
  paxTaskNumber?: number | undefined;
  dashboardOptions?: PatientDashboardOptionsModel | undefined;
  copyright?: string | undefined;
  coverPage?: string | undefined;
  authenticatedOnly: boolean;
  sectionsSelector?: boolean;
  disableGraphs?: boolean;
  consentCheck?: boolean;
  displaySectionComplete?: boolean;
  plotSubscales?: boolean;
  plotTscores?: boolean;
  backgroundImage?: string;
}

export interface PatientDashboardOptionsModel {
  disclaimerText?: string | undefined;
  clearRegion?: PatientDashboardRegionOptionsModel | undefined;
  stableRegion?: PatientDashboardRegionOptionsModel | undefined;
  borderlineRegion?: PatientDashboardRegionOptionsModel | undefined;
  clinicalRegion?: PatientDashboardRegionOptionsModel | undefined;
}

export interface PatientDashboardRegionOptionsModel {
  score: number;
  scoreText?: string | undefined;
  infoText?: string | undefined;
}

export interface SectionDataModel {
  heading?: string | undefined;
  dependsOn?: { [key: string]: number[] } | undefined;
  questions: QuestionDataModel[];
  sectionTitle?: string | undefined;
  backgroundImage?: string | undefined;
}

export interface QuestionDataModel {
  text?: string | undefined;
  shortText?: string | undefined;
  number: number;
  displayName?: string | undefined;
  hideNumber?: boolean | undefined;
  answers: AnswerDataModel[];
  questionType: QuestionType;
  subheading?: string | undefined;
  note?: string | undefined;
  divider?: boolean | undefined;
  mandatory?: boolean | undefined;
  goalId?: number | undefined;
  dependsOn?: { [key: string]: number[] } | undefined;
  labels: string[] | undefined;
  snomed?: string | undefined;
  maxAnswers?: number | undefined;
  rows?: string[] | undefined;
  rowNumber?: number | undefined;
  headers: string[];
  rowObjectName?: string | undefined;
  format?: string | undefined;
  disableResponses?: boolean | undefined;
  autofillFrom?: Autofill | undefined;
  goalCategory: GoalCategory;
}

export interface AnswerDataModel {
  number?: number | undefined;
  text?: string | undefined;
  score?: number | undefined;
  rangeMin?: number | undefined;
  rangeMax?: number | undefined;
  rangeDefault?: number | undefined;
  scoreCap?: number | undefined;
  rangeDecimal?: boolean | undefined;
  tableType?: TableQuestionType;
  tableDropdownChoices?: string[] | undefined;
  checked?: boolean | undefined;
  validationRegex?: string | undefined;
}

export interface ScoringDataModel {
  name?: string | undefined;
  questions?: number[] | undefined;
  maxScoringSubscales?: number[][] | undefined;
  meanScoring?: boolean | undefined;
  meanScoreDecimal?: boolean | undefined;
  meanScoreMissing?: boolean | undefined;
  minResponses?: number | undefined;
  scoreLessThanThreshold?: boolean | undefined;
  borderlineScore?: number | undefined;
  clinicalScore?: number | undefined;
  maxScore?: number | undefined;
  categoryType: CategoryType;
  scoreRules?: ScoringTransformationDataModel[] | undefined;
  additionalPlots?: AdditionalPlotDataModel | undefined;
  minTransformedScore?: number | undefined;
  maxTransformedScore?: number | undefined;
  snomed?: string | undefined;
  skippedWarningThreshold?: number | undefined;
}

export interface ScoringTransformationDataModel {
  rules?: ScoringRuleDataModel[] | undefined;
  operator?: ScoringRuleOperator | undefined;
  transformation?: number[] | undefined;
  hideGraph: boolean;
  borderlineOverride?: number | undefined;
  clinicalOverride?: number | undefined;
}

export interface AdditionalPlotDataModel {
  questionnaireId?: number;
  questionnaireVersion?: number;
  subscaleIndex?: number;
}

export interface ScoringRuleDataModel {
  question?: number | undefined;
  answer?: string | undefined;
  operator?: ScoringRuleOperator | undefined;
}

function mapQuestionnaire(q: QuestionnaireViewModel) {
  var qWIP: QuestionnaireBuilderDataModel = {
    id: q.id,
    active: q.active,
    created: q.created,
    updated: q.updated,
    name: q.name,
    spreadSheetName: q.spreadsheetName,
    title: q.title,
    description: q.description,
    sections: mapSections(q.sections),
    version: q.version,
    responseId: q.responseId,
    scoring: mapScoring(q.scoring),
    paxTaskNumber: q.paxTaskNumber,
    dashboardOptions: q.dashboardOptions,
    copyright: q.copyright,
    coverPage: q.coverPage,
    authenticatedOnly: q.authenticatedOnly,
    sectionsSelector: q.sectionsSelector,
    disableGraphs: q.disableGraphs,
    consentCheck: q.consentCheck,
    displaySectionComplete: q.displaySectionComplete,
    plotSubscales: q.plotSubscales,
    plotTscores: q.plotTscores,
    backgroundImage: q.backgroundImage,
  };
  return qWIP;
}

function mapSections(s: SectionViewModel[] | undefined) {
  var sWIP: SectionDataModel[] = [];
  if (s != null) {
    sWIP = s.map((section) => {
      var sectionData: SectionDataModel = {
        heading: section.heading,
        dependsOn: section.dependsOn,
        questions: mapQuestions(section.questions),
        sectionTitle: section.sectionTitle,
        backgroundImage: section.backgroundImage,
      };
      return sectionData;
    });
  }
  return sWIP;
}

function mapQuestions(q: QuestionViewModel[] | undefined) {
  var qWIP: QuestionDataModel[] = [];
  if (q != null) {
    qWIP = q.map((question) => {
      var questionData: QuestionDataModel = {
        text: question.text,
        shortText: question.shortText,
        number: question.number,
        displayName: question.displayName,
        hideNumber: question.hideNumber,
        answers: mapAnswers(question.answers),
        autofillFrom: question.autofillFrom,
        questionType: question.questionType,
        subheading: question.subheading,
        note: question.note,
        divider: question.divider,
        mandatory: question.mandatory,
        goalId: question.goalId,
        dependsOn: question.dependsOn,
        labels: question.labels,
        snomed: question.snomed,
        maxAnswers: question.maxAnswers,
        rows: question.rows,
        rowNumber: question.rowNumber,
        headers: question.headers ?? [],
        rowObjectName: question.rowObjectName,
        format: question.format,
        disableResponses: question.disableResponses,
        goalCategory: question.goalCategory ?? GoalCategory.General,
      };
      return questionData;
    });
  }
  return qWIP;
}

function mapAnswers(a: AnswerViewModel[] | undefined) {
  var aWIP: AnswerDataModel[] = [];
  if (a != null) {
    aWIP = a.map((answer) => {
      var answerData: AnswerDataModel = {
        number: answer.number,
        text: answer.text,
        score: answer.score,
        rangeMin: answer.rangeMin,
        rangeMax: answer.rangeMax,
        rangeDefault: answer.rangeDefault,
        scoreCap: answer.scoreCap,
        rangeDecimal: answer.rangeDecimal,
        tableType: answer.tableType,
        tableDropdownChoices: answer.tableDropdownChoices,
        checked: answer.checked,
        validationRegex: answer.validationRegex,
      };
      return answerData;
    });
  }
  return aWIP;
}

function mapScoring(sc: ScoringLogicViewModel[] | undefined) {
  var scWIP: ScoringDataModel[] = [];
  if (sc != null) {
    scWIP = sc.map((scoring) => {
      var scoringData: ScoringDataModel = {
        name: scoring.name,
        questions: scoring.questions,
        maxScoringSubscales: scoring.maxScoringSubscales,
        meanScoring: scoring.meanScoring ?? false,
        meanScoreDecimal: scoring.meanScoreDecimal ?? false,
        meanScoreMissing: scoring.meanScoreMissing ?? false,
        minResponses: scoring.minResponses,
        scoreLessThanThreshold: scoring.scoreLessThanThreshold ?? false,
        borderlineScore: scoring.borderlineScore,
        clinicalScore: scoring.clinicalScore,
        maxScore: scoring.maxScore,
        categoryType: scoring.categoryType,
        scoreRules: mapScoringTransformations(scoring.scoreRules ?? []),
        additionalPlots: scoring.additionalPlots,
        minTransformedScore: scoring.minTransformedScore,
        maxTransformedScore: scoring.maxTransformedScore,
        snomed: scoring.snomed,
        skippedWarningThreshold: scoring.skippedWarningThreshold,
      };
      return scoringData;
    });
  }
  return scWIP;
}

function mapScoringTransformations(sTVM: ScoringTransformationViewModel[]) {
  var sTWIP: ScoringTransformationDataModel[] = [];
  if (sTVM != null) {
    sTWIP = sTVM.map((transformation) => {
      var transformationData: ScoringTransformationDataModel = {
        rules: mapScoringRules(transformation.rules ?? []),
        operator: transformation.operator,
        transformation: transformation.transformation,
        hideGraph: transformation.hideGraph,
        borderlineOverride: transformation.borderlineOverride,
        clinicalOverride: transformation.clinicalOverride,
      };
      return transformationData;
    });
  }
  return sTWIP;
}

function mapScoringRules(sRVM: ScoringRuleViewModel[]) {
  var sRWIP: ScoringRuleDataModel[] = [];
  if (sRVM != null) {
    sRWIP = sRVM.map((rule) => {
      var scoringData: ScoringRuleDataModel = {
        question: rule.question,
        answer: rule.answer,
        operator: rule.operator,
      };
      return scoringData;
    });
  }
  return sRWIP;
}

export function convertDataModelToViewModel(
  questionnaireWIP: QuestionnaireBuilderDataModel
) {
  const created: Date =
    questionnaireWIP.created == null ? new Date(0) : questionnaireWIP.created;
  const updated: Date = new Date(0);

  var qIVM: IQuestionnaireViewModel = {
    id: questionnaireWIP.id == null ? -1 : questionnaireWIP.id,
    active: questionnaireWIP.active ?? true,
    created: created,
    updated: updated,
    name: questionnaireWIP.name,
    spreadsheetName: questionnaireWIP.spreadSheetName,
    title: questionnaireWIP.title,
    description: questionnaireWIP.description,
    sections: convertSectionDMtoVM(questionnaireWIP.sections),
    version: questionnaireWIP.version ?? 1,
    responseId: questionnaireWIP.responseId,
    scoring: convertScoringDMtoVM(questionnaireWIP.scoring),
    paxTaskNumber: questionnaireWIP.paxTaskNumber,
    dashboardOptions: convertDashboardDMtoVM(questionnaireWIP.dashboardOptions),
    copyright: questionnaireWIP.copyright,
    coverPage: questionnaireWIP.coverPage,
    authenticatedOnly: questionnaireWIP.authenticatedOnly,
    sectionsSelector: questionnaireWIP.sectionsSelector ?? false,
    disableGraphs: questionnaireWIP.disableGraphs ?? false,
    consentCheck: questionnaireWIP.consentCheck ?? false,
    displaySectionComplete: questionnaireWIP.displaySectionComplete ?? false,
    plotSubscales: questionnaireWIP.plotSubscales ?? false,
    plotTscores: questionnaireWIP.plotTscores ?? false,
    backgroundImage: questionnaireWIP.backgroundImage ?? "",
  };

  return qIVM;
}

function convertSectionDMtoVM(sDM: SectionDataModel[]) {
  var sVM: SectionViewModel[] = [];
  if (sDM != null) {
    sVM = sDM.map((section) => {
      var sectionData: ISectionViewModel = {
        heading: section.heading,
        dependsOn: section.dependsOn,
        questions: convertQuestionsDMtoVM(section.questions),
        sectionTitle: section.sectionTitle,
        backgroundImage: section.backgroundImage,
      };
      return new SectionViewModel(sectionData);
    });
  }
  return sVM;
}

function convertDashboardDMtoVM(pDM: PatientDashboardOptionsModel | undefined) {
  if (pDM != null) {
    var pVM: IPatientDashboardOptionsViewModel = {
      disclaimerText: pDM.disclaimerText,
      clearRegion: convertDashboardRegionDMtoVM(pDM.clearRegion),
      stableRegion: convertDashboardRegionDMtoVM(pDM.stableRegion),
      borderlineRegion: convertDashboardRegionDMtoVM(pDM.borderlineRegion),
      clinicalRegion: convertDashboardRegionDMtoVM(pDM.clinicalRegion),
    };
    return new PatientDashboardOptionsViewModel(pVM);
  }
}

function convertDashboardRegionDMtoVM(
  pDM: PatientDashboardRegionOptionsModel | undefined
) {
  if (pDM != null) {
    var pVM: IPatientDashboardRegionOptionsViewModel = {
      score: pDM.score,
      scoreText: pDM.scoreText,
      infoText: pDM.infoText,
    };
    return new PatientDashboardRegionOptionsViewModel(pVM);
  }
}

function convertQuestionsDMtoVM(qDM: QuestionDataModel[]) {
  var qVM: QuestionViewModel[] = [];
  if (qDM != null) {
    qVM = qDM.map((question) => {
      var questionData: IQuestionViewModel = {
        text: question.text,
        shortText: question.shortText,
        number: question.number,
        displayName: question.displayName,
        hideNumber: question.hideNumber,
        answers: convertAnswersDMtoVM(question.answers),
        questionType: question.questionType,
        subheading: question.subheading,
        note: question.note,
        divider: question.divider,
        mandatory: question.mandatory,
        goalId: question.goalId,
        dependsOn: question.dependsOn,
        autofillFrom: question.autofillFrom,
        labels: question.labels,
        snomed: question.snomed,
        maxAnswers: question.maxAnswers,
        rows: question.rows,
        rowNumber: question.rowNumber,
        headers: question.headers ?? [],
        rowObjectName: question.rowObjectName,
        format: question.format,
        goalCategory: question.goalCategory,
      };
      return new QuestionViewModel(questionData);
    });
  }
  return qVM;
}

function convertAnswersDMtoVM(aDM: AnswerDataModel[]) {
  var aVM: AnswerViewModel[] = [];
  if (aDM != null) {
    aVM = aDM.map((answer) => {
      var answerData: IAnswerViewModel = {
        number: answer.number,
        text: answer.text,
        score: answer.score,
        rangeMin: answer.rangeMin,
        rangeMax: answer.rangeMax,
        rangeDefault: answer.rangeDefault,
        scoreCap: answer.scoreCap,
        rangeDecimal: answer.rangeDecimal,
        tableType: answer.tableType ?? 0,
        tableDropdownChoices: answer.tableDropdownChoices,
        validationRegex: answer.validationRegex,
      };
      return new AnswerViewModel(answerData);
    });
  }
  return aVM;
}

function convertScoringDMtoVM(sDM: ScoringDataModel[]) {
  var sVM: ScoringLogicViewModel[] = [];
  if (sDM != null) {
    sVM = sDM.map((score) => {
      var ScoringData: IScoringLogicViewModel = {
        name: score.name,
        questions: score.questions,
        maxScoringSubscales: score.maxScoringSubscales,
        meanScoring: score.meanScoring,
        meanScoreDecimal: score.meanScoreDecimal,
        meanScoreMissing: score.meanScoreMissing,
        minResponses: score.minResponses,
        scoreLessThanThreshold: score.scoreLessThanThreshold,
        borderlineScore: score.borderlineScore,
        clinicalScore: score.clinicalScore,
        maxScore: score.maxScore,
        categoryType: score.categoryType,
        scoreRules: convertScoringTransformationDMtoVM(score.scoreRules ?? []),
        additionalPlots: convertAdditionalPlotsDMtoVM(score.additionalPlots),
        minTransformedScore: score.minTransformedScore,
        maxTransformedScore: score.maxTransformedScore,
        snomed: score.snomed,
        skippedWarningThreshold: score.skippedWarningThreshold,
      };
      return new ScoringLogicViewModel(ScoringData);
    });
  }
  return sVM;
}

function convertScoringTransformationDMtoVM(
  sTDM: ScoringTransformationDataModel[]
) {
  var sTVM: ScoringTransformationViewModel[] = [];
  if (sTDM != null) {
    sTVM = sTDM.map((transformation) => {
      var ScoringData: IScoringTransformationViewModel = {
        rules: convertScoringRulesDMtoVM(transformation.rules ?? []),
        operator: transformation.operator,
        transformation: transformation.transformation,
        hideGraph: transformation.hideGraph,
        borderlineOverride: transformation.borderlineOverride,
        clinicalOverride: transformation.clinicalOverride,
      };
      return new ScoringTransformationViewModel(ScoringData);
    });
  }
  return sTVM.length === 0 ? undefined : sTVM;
}

function convertAdditionalPlotsDMtoVM(
  aPDM: AdditionalPlotDataModel | undefined
) {
  if (aPDM != null) {
    var PlotData: IAdditionalPlotViewModel = {
      questionnaireId: aPDM.questionnaireId,
      questionnaireVersion: aPDM.questionnaireVersion,
      subscaleIndex: aPDM.subscaleIndex,
    };
    return new AdditionalPlotViewModel(PlotData);
  }
  return undefined;
}

function convertScoringRulesDMtoVM(sRDM: ScoringRuleDataModel[]) {
  var sRVM: ScoringRuleViewModel[] = [];
  if (sRDM != null) {
    sRVM = sRDM.map((rule) => {
      var ScoringData: IScoringRuleViewModel = {
        question: rule.question,
        answer: rule.answer,
        operator: rule.operator,
      };
      return new ScoringRuleViewModel(ScoringData);
    });
  }
  return sRVM.length === 0 ? undefined : sRVM;
}

export enum DataModelType {
  Questionnaire,
  Section,
  Question,
  Answer,
  Scoring,
}
