import { Button, Container, Dialog, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { theme as customTheme } from "../../../../Theme";
import { PatientTab } from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { UserContext } from "../stores/UserStore";
import { getCookie } from "../../../utils/cookie";
import { useSearchParams } from "react-router-dom";

interface Props {
  open: boolean;
  patientId: string | null;
  editing: boolean;
  goal: boolean;
  fromNotification: boolean;
}
const SubmissionDialog = (props: Props): JSX.Element => {
  const useStyle = makeStyles((th: Theme) => ({
    mainContainer: {
      padding: 18,
    },
  }));
  const classes = useStyle(customTheme);
  const [searchParams] = useSearchParams();
  const getSubmissionText = (): String => {
    var text =
      "Thank you, your " +
      (props.goal ? "response has" : "responses have") +
      " been submitted.";
    if (props.fromNotification || props.goal) {
      text += " You may now close this window.";
    }
    return text;
  };
  const user = useContext(UserContext);
  const userIsPatient =
    user?.id === undefined || user.id === null || user.id <= 0;
  const isContextual = searchParams.get("c") === "1";
  return (
    <Dialog open={props.open}>
      <Container className={classes.mainContainer}>
        <Typography variant="h5">
          {props.goal ? "Goal" : "Questionnaires"} completed
        </Typography>
        <hr />
        <Typography variant="body1">{getSubmissionText()}</Typography>
        <hr />
        <Button
          variant="contained"
          hidden={props.fromNotification || props.goal}
          onClick={() => {
            const viewName = isContextual ? "contextualView" : "viewPatient";
            const viewPatientLink =
              userIsPatient || getCookie("TrueColours_Expiration").length === 0
                ? `/login/${viewName},`
                : `/${viewName}?p=`;

            window.location.href =
              Configuration.SITEBASE +
              viewPatientLink +
              props.patientId +
              (userIsPatient ? "_t=" : "&t=") +
              (props.editing
                ? PatientTab.Timeline.toString()
                : PatientTab.Questionnaires.toString());
          }}
        >
          {userIsPatient ? "Clinician Login" : "Return to Patient"}
        </Button>
      </Container>
    </Dialog>
  );
};

export default SubmissionDialog;
