import { Button, Container, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme as customTheme } from "../../../../Theme";
import { useContext, useEffect, useState } from "react";
import {
  CaseloadClient,
  CaseloadType,
  CaseloadViewModel,
  IdentityClient,
  PatientViewModel,
  QuestionnaireClient,
  QuestionnaireSetViewModel,
  QuestionnaireViewModel,
  UserViewModel,
} from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { FetchOverride } from "../../../utils/Request";
import { CheckIsMobile } from "../../../utils/MobileStatus";
import CaseloadQuestionnaireDropdowns from "../../schedule/create-edit-schedule/CaseloadQuestionnaireDropdowns";
import { theme } from "../../../../Theme";
import { Error400 } from "../../elements/errors/Error400";
import { Error500 } from "../../elements/errors/Error500";
import { Error401 } from "../../elements/errors/Error401";
import { UserDispatchContext } from "../../elements/stores/UserStore";
import { useMountedState, useUnmountPromise } from "react-use";

interface Props {
  patient: PatientViewModel;
}

const OneOffQuestionnaireAllocation = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const useStyle = makeStyles((th: Theme) => ({
    continueButton: {
      backgroundColor: th.palette.secondary.main,
      color: "white",
      margin: 5,
      float: "right",
      "&:hover": {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    checkboxesContainer: {
      display: "inline-flex",
      flexWrap: "wrap",
    },
    checkboxesMarginRight: {
      flexBasis: isMobile ? "default" : "40%",
      marginRight: isMobile ? "default" : "auto",
    },
    checkboxesMarginRightEnd: {
      marginLeft: isMobile ? "default" : "9.5%",
    },
    checkboxesMarginLeft: {
      flexBasis: isMobile ? "default" : "40%",
      marginLeft: isMobile ? "default" : "auto",
    },
  }));
  const classes = useStyle(customTheme);
  const setUser = useContext(UserDispatchContext);

  const [allQuestionnaires, setAllQuestionnaires] = useState<
    QuestionnaireViewModel[]
  >([]);

  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState<
    QuestionnaireViewModel[]
  >([]);

  const [caseloadId, setCaseloadId] = useState<number | undefined>(undefined);

  const [statusCode, setStatusCode] = useState<number>();

  const [allCaseloads, setAllCaseloads] = useState<CaseloadViewModel[]>([]);
  const resolveWhileMounted = useUnmountPromise();
  const isMountedState = useMountedState();
  const isContextual = window.location.href.indexOf("contextual") > -1;
  useEffect(() => {
    const effect = async () => {
      await resolveWhileMounted(
        new CaseloadClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        ).getCaseloadsForPatient(props.patient.id, CaseloadType.Shared, true)
      ).then((c) => setAllCaseloads(c));
    };
    effect();
  }, [props.patient.id, resolveWhileMounted]);

  const handleContinue = async (event: any, doLogout: boolean) => {
    if (isMountedState()) {
      var identityClient = new IdentityClient(
        Configuration.SERVER_ROOT,
        FetchOverride
      );
      var questionSetModel = new QuestionnaireSetViewModel({
        patientId: props.patient.id,
        caseloadId: caseloadId,
        questionnaires: selectedQuestionnaires.map((x) => x.id),
      });
      await resolveWhileMounted(
        new QuestionnaireClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        ).prepareQuestionnaireResponses(questionSetModel)
      )
        .then((response: string[]) => {
          var joinedTokens = response.join(",");
          if (doLogout) {
            setUser(new UserViewModel());
          }
          window.location.href = doLogout
            ? identityClient.getLogoutToRedirectLinkURL(
                "/questionnaire?t=" +
                  joinedTokens +
                  "&p=" +
                  props.patient.id +
                  "&tId=" +
                  props.patient.tenantId +
                  "&lo=1" +
                  "&c=" +
                  (isContextual ? "1" : "0")
              )
            : Configuration.SITEBASE +
              "/questionnaire?t=" +
              joinedTokens +
              "&p=" +
              props.patient.id +
              "&tId=" +
              props.patient.tenantId +
              "&c=" +
              (isContextual ? "1" : "0");
        })
        .catch((e) => {
          if (isMountedState()) {
            setStatusCode(e.status);
          }
        });
    }
  };

  async function setCaseload(caseloadId: number) {
    setCaseloadId(caseloadId);
    await resolveWhileMounted(
      new CaseloadClient(
        Configuration.SERVER_ROOT,
        FetchOverride
      ).getQuestionnairesForCaseload(caseloadId)
    )
      .then((questionnaires: QuestionnaireViewModel[]) => {
        setAllQuestionnaires(questionnaires);
        setSelectedQuestionnaires([]);
        setStatusCode(undefined);
      })
      .catch((e) => {
        if (isMountedState()) {
          setStatusCode(e.status);
        }
      });
  }

  return (
    <Container>
      {statusCode === 400 ? (
        <Error400 />
      ) : statusCode === 401 ? (
        <Error401 />
      ) : statusCode === undefined ? (
        <></>
      ) : (
        <Error500 />
      )}

      <CaseloadQuestionnaireDropdowns
        allCaseloads={allCaseloads}
        patient={props.patient}
        setSelectedQuestionnaires={setSelectedQuestionnaires}
        setCaseloadId={setCaseload}
        selectedQuestionnaires={selectedQuestionnaires}
        caseloadId={caseloadId}
        allQuestionnaires={allQuestionnaires}
        caseloadType={CaseloadType.Shared}
        setPromptSchedule={null}
      />
      <Button
        className={classes.continueButton}
        variant="contained"
        onClick={(event) => handleContinue(event, true)}
        disabled={
          selectedQuestionnaires.length === 0 ||
          selectedQuestionnaires.filter((x) => x.authenticatedOnly).length > 0
        }
        aria-label="Continue"
      >
        Continue as Patient
      </Button>
      <Button
        className={classes.continueButton}
        variant="contained"
        onClick={(event) => handleContinue(event, false)}
        disabled={selectedQuestionnaires.length === 0}
      >
        Continue as Clinician
      </Button>
    </Container>
  );
};

export default OneOffQuestionnaireAllocation;
