import {
  Button,
  Card,
  CardContent,
  Container,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Edit,
  Archive,
  Pause,
  PlayArrow,
  StarRate,
  DoneAll,
} from "@mui/icons-material";
import {
  GoalCategory,
  GoalOwner,
  MonitoringStatus,
  PromptScheduleViewModel,
  Role,
} from "../../../../types/auto/types";
import moment from "moment";
import {
  GetGoalOwnerDesciption,
  GetMonitoringDescriptions,
  GetReportingFrequencyString,
} from "../../schedule/PromptFrequencyReporting";
import { CheckIsMobile, CheckIsSmallDevice } from "../../../utils/MobileStatus";
import { GetLastScheduledPromptDate } from "../../patient/patient-details/ScheduledQuestionnaireCardHelper";
import { theme } from "../../../../Theme";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { ToTitleCase } from "../../../utils/TitleCase";

interface Props {
  title: string;
  monitoringStatus: MonitoringStatus;
  showPrompt?: boolean;
  prompt: PromptScheduleViewModel | undefined;
  createdDate: Date;
  lastResponse: Date | undefined;
  lastResponseValue?: number;
  onEdit?: () => void;
  onRate?: () => void;
  onSuspendResume?: (suspend: boolean) => void;
  onComplete?: () => void;
  onArchived?: () => void;
  onUnarchiving?: () => void;
  owner?: GoalOwner;
  role?: Role;
  goalOwnerDescriptions?: {
    [key in keyof typeof GoalOwner]?: string;
  };
  monitoringStatusDescriptions: {
    [key in keyof typeof MonitoringStatus]?: string;
  };
  goalCategory?: GoalCategory;
  goalQuestionnaireName?: string;
  readOnly: boolean;
}

const PromptableCard = (props: Props): JSX.Element => {
  const isMobile = CheckIsMobile();
  const isSmallDevice = CheckIsSmallDevice();
  const useStyle = makeStyles((th: Theme) => ({
    headerAndActions: {
      display: "flex",
      paddingRight: "0",
      paddingLeft: "0",
    },
    headingText: {
      fontWeight: "bold",
    },
    vert: {
      marginLeft: "auto",
      padding: "0",
      minWidth: "24px",
    },
    subTitle: {
      color: "gray",
      fontSize: 15,
    },
    card: {
      marginTop: "10px",
      width: isMobile ? "100%" : "49.5%",
      marginRight: isMobile ? "auto" : "0.5%",
      minWidth: isSmallDevice ? "180px" : "250px",
      borderRadius: "4px",
      borderWidth: "thin",
      borderColor: th.palette.grey[50],
      borderStyle: "solid",
      "&:nth-child(even)": {
        marginRight: isMobile ? "auto" : "0",
        marginLeft: isMobile ? "auto" : "0.5%",
      },
    },
    menuOption: { marginLeft: 10 },
    goalCompleteStatus: {
      color: "gray",
      display: "inline",
      fontSize: 15,
    },
    completeGoalNow: {
      color: "red",
      fontSize: 15,
      display: "inline",
    },
  }));
  const classes = useStyle(theme);

  const [anchorActionList, setAnchorActionList] = useState<null | HTMLElement>(
    null
  );

  const isStaff =
    props.role === Role.SuperAdmin ||
    props.role === Role.Admin ||
    props.role === Role.Clinician;

  const getCompleteStatusMessage = (): JSX.Element => {
    if (
      props.prompt !== undefined &&
      (props.lastResponse === undefined ||
        props.lastResponse === null ||
        GetLastScheduledPromptDate(props.prompt) > props.lastResponse)
    ) {
      return (
        <Typography className={classes.completeGoalNow}>
          Please rate this goal now.
        </Typography>
      );
    } else {
      return <></>;
    }
  };

  var ownerString = "";
  const isSuspended = props.monitoringStatus === MonitoringStatus.Suspended;
  if (
    props.goalOwnerDescriptions !== undefined &&
    props.goalOwnerDescriptions !== null
  ) {
    ownerString =
      props.owner !== null && props.owner !== undefined
        ? " (" +
          GetGoalOwnerDesciption(props.goalOwnerDescriptions, props.owner) +
          ")"
        : "";
  }

  const monitoringStatus =
    props.monitoringStatus === MonitoringStatus.Suspended ||
    props.monitoringStatus === MonitoringStatus.ArchivedDueToEndDateReached ||
    props.monitoringStatus === MonitoringStatus.ArchivedDueToMissedResponses ||
    props.monitoringStatus === MonitoringStatus.ArchivedDueToAnsweredResponses
      ? " - " +
        GetMonitoringDescriptions(
          props.monitoringStatusDescriptions,
          props.monitoringStatus
        )
      : "";

  const handleClose = () => {
    setAnchorActionList(null);
  };

  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent>
        <Container className={classes.headerAndActions}>
          <Typography className={classes.headingText}>
            {props.title + monitoringStatus + ownerString}
          </Typography>

          {(props.onRate ||
            props.onEdit ||
            props.onSuspendResume ||
            props.onComplete ||
            props.onArchived ||
            props.onUnarchiving) &&
            !props.readOnly && (
              <Button
                onClick={(event) =>
                  setAnchorActionList((val) =>
                    val === null ? event.currentTarget : null
                  )
                }
                aria-label="Open Action List"
                className={classes.vert}
              >
                <MoreVertIcon />
                <Menu
                  open={anchorActionList !== null}
                  anchorEl={anchorActionList}
                  aria-label="Action List"
                  onClose={handleClose}
                >
                  {props.onRate && (
                    <MenuItem
                      onClick={() => props.onRate && props.onRate()}
                      aria-label="Rate"
                      role="menuitem"
                    >
                      <StarRate />
                      <Typography className={classes.menuOption}>
                        Rate
                      </Typography>
                    </MenuItem>
                  )}
                  {props.onEdit && (
                    <MenuItem
                      onClick={() => props.onEdit && props.onEdit()}
                      aria-label="Edit"
                      role="menuitem"
                    >
                      <Edit />
                      <Typography className={classes.menuOption}>
                        Edit
                      </Typography>
                    </MenuItem>
                  )}
                  {props.onSuspendResume && isStaff && (
                    <MenuItem
                      onClick={() =>
                        props.onSuspendResume &&
                        props.onSuspendResume(!isSuspended)
                      }
                      aria-label={isSuspended ? "Resume" : "Suspend"}
                      role="menuitem"
                    >
                      {isSuspended ? <PlayArrow /> : <Pause />}
                      <Typography className={classes.menuOption}>
                        {isSuspended ? "Resume" : "Suspend"}
                      </Typography>
                    </MenuItem>
                  )}
                  {props.onComplete && (
                    <MenuItem
                      onClick={() => props.onComplete && props.onComplete()}
                      aria-label="Complete"
                      role="menuitem"
                    >
                      <DoneAll />
                      <Typography className={classes.menuOption}>
                        Complete
                      </Typography>
                    </MenuItem>
                  )}
                  {props.onArchived && isStaff && (
                    <MenuItem
                      onClick={() => props.onArchived && props.onArchived()}
                      aria-label="Archive"
                      role="menuitem"
                    >
                      <Archive />
                      <Typography className={classes.menuOption}>
                        Archive
                      </Typography>
                    </MenuItem>
                  )}
                  {props.onUnarchiving && isStaff && (
                    <MenuItem
                      onClick={() =>
                        props.onUnarchiving && props.onUnarchiving()
                      }
                      aria-label="Unarchive"
                      role="menuitem"
                    >
                      <UnarchiveIcon />
                      <Typography className={classes.menuOption}>
                        Unarchive
                      </Typography>
                    </MenuItem>
                  )}
                </Menu>
              </Button>
            )}
        </Container>

        <Typography className={classes.subTitle}>
          {"Created: " +
            moment(props.createdDate).format("DD-MM-YYYY") +
            (props.lastResponse
              ? " - Last Response: " +
                moment(props.lastResponse).format("DD-MM-YYYY")
              : " - No Response")}
        </Typography>
        {props.showPrompt && (
          <Typography className={classes.subTitle}>
            {"Prompt: " +
              (props.prompt
                ? GetReportingFrequencyString(props.prompt)
                : "Unprompted")}
          </Typography>
        )}
        {props.lastResponseValue !== undefined &&
          props.lastResponseValue !== null && (
            <Typography className={classes.subTitle}>
              {"Last Rating: " + props.lastResponseValue}
            </Typography>
          )}
        {props.goalCategory != null && props.goalQuestionnaireName != null && (
          <>
            <Typography className={classes.subTitle}>
              {"Questionnaire: " + props.goalQuestionnaireName}
            </Typography>
            <Typography className={classes.subTitle}>
              {"Goal Category: " +
                ToTitleCase(
                  GoalCategory[props.goalCategory]
                    .toString()
                    .replace("AndOr", "/")
                )}
            </Typography>
          </>
        )}
        {props.onRate &&
          props.monitoringStatus === MonitoringStatus.Active &&
          getCompleteStatusMessage()}
      </CardContent>
    </Card>
  );
};

export default PromptableCard;
