import { useEffect, useState } from "react";
import { IdentityClient } from "../../../../types/auto/types";
import { Configuration } from "../../../Constants";
import { getCookie } from "../../../utils/cookie";
import { FetchOverride } from "../../../utils/Request";
import TimeoutAlert from "./TimeoutAlert";

export const TimeoutComponent = (): JSX.Element => {
  const [timeoutSwitch, setTimeoutSwitch] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  function getPath(url: string): string | null {
    const urlParts = url.split("/");
    if (urlParts.length < 4) {
      return null;
    }
    return "/" + urlParts.slice(3).join("/");
  }

  var currentLocation = getPath(window.location.href);
  useEffect(() => {
    const handler = setTimeout(() => {
      if (
        parseInt(getCookie("TrueColours_Expiration")) -
          Math.floor(Date.now() / 1000) <=
        60
      ) {
        setOpenAlert(true);
      }
      if (
        getCookie("TrueColours_Expiration").length === 0 ||
        parseInt(getCookie("TrueColours_Expiration")) -
          Math.floor(Date.now() / 1000) <=
          0
      ) {
        window.location.href = new IdentityClient(
          Configuration.SERVER_ROOT,
          FetchOverride
        ).getLogoutToRedirectLinkURL(
          "/login" +
            (currentLocation == null
              ? ""
              : currentLocation
                  .replace("?p=", ",")
                  .replace("?s=", ",")
                  .replace("?qId=", ",")
                  .replaceAll("&", "_"))
        );
      }
      setTimeoutSwitch(!timeoutSwitch);
    }, Configuration.TIMEOUTCHECK);
    return () => clearTimeout(handler);
  }, [timeoutSwitch, currentLocation]);
  return (
    <>
      <TimeoutAlert open={openAlert} setOpen={setOpenAlert} />
    </>
  );
};

export default TimeoutComponent;
