import { QuestionnaireViewModel } from "../../../../types/auto/types";

export const IsCaseloadValid = (caseloadId: number): boolean =>
  caseloadId !== null && caseloadId !== undefined;

export const IsQuestionnaireValid = (
  questionnaires: QuestionnaireViewModel[]
): boolean => questionnaires !== null && questionnaires.length !== 0;

export const IsEndDateValid = (
  startDate: Date,
  endDate: Date | undefined,
  continueIndefinitely: boolean
): boolean =>
  (endDate !== undefined && !continueIndefinitely && endDate >= startDate) ||
  continueIndefinitely;
